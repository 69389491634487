import * as React from "react";
import { Box, Text } from "@chakra-ui/react";

const sectionProps = {
  m: "auto",
  h: "100vh",
  w: "100%",
  p: [null, 16],
};

const LAST_UPDATE_TERMS = "30 de Marzo de 2023";

const text = [
  {
    id: "conditions",
    title: "Términos y Condiciones",
    content: `
Aceptación de los Términos y Condiciones
Al utilizar el sitio web de Tenku y/o contratar alguno de nuestros servicios o productos, usted acepta estos Términos y Condiciones ("Términos") en su totalidad. Si no está de acuerdo con estos Términos, no debe utilizar nuestro sitio web ni contratar nuestros servicios.

Modificación de los Términos y Condiciones
Tenku se reserva el derecho de modificar estos Términos en cualquier momento. Las modificaciones entrarán en vigor cuando se publiquen en el sitio web. Al continuar utilizando el sitio web y nuestros servicios después de la publicación de las modificaciones, usted acepta los Términos modificados.

Elegibilidad y Registro
Para contratar nuestros servicios, debe ser mayor de 18 años y tener la capacidad legal para celebrar un contrato. Al registrarse en nuestro sitio web, usted garantiza que toda la información proporcionada es precisa y veraz.

Descripción de los servicios y productos
Tenku ofrece membresías de protección contra accidentes con diferentes niveles de cobertura. Al seleccionar y comprar una membresía, usted acepta los términos específicos de la cobertura, incluidas las exclusiones y limitaciones aplicables.

Precio y pago
Los precios de las membresías están expresados en pesos mexicanos (MXN) e incluyen impuestos aplicables. El pago debe realizarse a través de los métodos de pago aceptados por Tenku. Al realizar una compra, usted acepta cumplir con los términos y condiciones de nuestro proveedor de servicios de pago.

Proceso de reclamaciones
Para presentar una reclamación, siga el proceso descrito en nuestro sitio web o en la documentación proporcionada al contratar la membresía. Tenku se reserva el derecho de investigar y verificar la validez de las reclamaciones antes de proporcionar la indemnización correspondiente.

Exclusiones y limitaciones
Las exclusiones y limitaciones de cada membresía se detallan en nuestro sitio web y en la documentación proporcionada. Es su responsabilidad leer y comprender estas exclusiones y limitaciones antes de contratar una membresía.

Cancelaciones y reembolsos
Si desea cancelar su membresía, comuníquese con nuestro servicio de atención al cliente. Los reembolsos están sujetos a las políticas de reembolso de Tenku, que se detallan en nuestro sitio web.

Propiedad intelectual
El contenido del sitio web de Tenku, incluidos los logotipos, imágenes, textos y diseño, es propiedad de Tenku y está protegido por las leyes de propiedad intelectual. Está prohibido el uso no autorizado, la copia o la distribución de dicho contenido.

Limitación de responsabilidad
En la medida permitida por la ley, Tenku no será responsable de ningún daño indirecto, incidental, especial o consecuente que surja del uso de nuestro sitio web o de la contratación de nuestros servicios.

Ley aplicable y jurisdicción
Estos Términos se rigen e interpretan de acuerdo con las leyes de México. En caso de disputa, las partes acuerdan someterse a la jurisdicción exclusiva de los tribunales competentes en México.
`,
  },
  {
    id: "privacy",
    title: "Aviso de privacidad",
    content: `
    Tenku se compromete a proteger la privacidad de sus usuarios. Este Aviso de Privacidad explica cómo recopilamos, utilizamos y protegemos la información personal que nos proporciona al utilizar nuestro sitio web y servicios. Al utilizar nuestro sitio web y servicios, usted acepta la recopilación y uso de su información personal de acuerdo con este Aviso de Privacidad.

Información que recopilamos
Podemos recopilar la siguiente información personal cuando utiliza nuestro sitio web y servicios:

Nombre completo
Fecha de nacimiento
Teléfono
Dirección de correo electrónico
Código postal

Uso de la información
Utilizamos la información que nos proporciona para:

Procesar su registro y administrar su cuenta.
Proporcionar, mantener y mejorar nuestros servicios.
Procesar y gestionar sus pagos y suscripciones.
Responder a sus preguntas y solicitudes.
Enviar comunicaciones relacionadas con nuestros servicios, promociones y noticias.
Protección de su información
Tenemos medidas de seguridad adecuadas para proteger su información personal de acceso no autorizado, divulgación, alteración o destrucción. Sin embargo, ninguna transmisión de datos a través de Internet es completamente segura, por lo que no podemos garantizar la seguridad total de su información.

Compartir información con terceros
No vendemos ni compartimos su información personal con terceros para fines de marketing sin su consentimiento explícito. Sin embargo, podemos compartir su información con terceros en las siguientes situaciones:

Cuando sea necesario para la prestación de nuestros servicios.
Cuando sea necesario para cumplir con nuestras obligaciones legales.
En caso de una fusión, adquisición o venta de activos de la empresa.
Retención de datos
Retendremos su información personal durante el tiempo necesario para cumplir con los fines descritos en este Aviso de Privacidad, a menos que la ley exija o permita un período de retención más largo.

Sus derechos
Usted tiene derecho a acceder, corregir, actualizar, eliminar o limitar el uso de su información personal en cualquier momento. Para ejercer estos derechos, comuníquese con nosotros utilizando la información de contacto proporcionada en nuestro sitio web.

Cambios en este Aviso de Privacidad
Tenku se reserva el derecho de actualizar o modificar este Aviso de Privacidad en cualquier momento. Le recomendamos que revise periódicamente este Aviso de Privacidad para estar informado sobre cómo protegemos su información.

Contacto
Si tiene alguna pregunta o inquietud sobre este Aviso de Privacidad o nuestras prácticas de privacidad, comuníquese con nosotros utilizando la información de contacto proporcionada en nuestro sitio web.

Fecha de la última actualización: ${LAST_UPDATE_TERMS}
`,
  },
];

export const TermsAndConditions = () => (
  <Box {...sectionProps}>
    {text.map(({ id, title, content }, idx) => {
      return (
        <Box py={10} key={`content-${idx}`} id={id}>
          <Text
            fontWeight="800"
            color="dark-blue"
            fontSize={[40, 49]}
            lineHeight="60px"
            letterSpacing="0.105em"
            align="center"
          >
            {title}
          </Text>
          <Text
            paddingTop={2}
            color="black"
            align="left"
            fontSize={19}
            fontWeight={400}
            letterSpacing="0.105em"
            whiteSpace="pre-line"
          >
            {content}
          </Text>
        </Box>
      );
    })}
  </Box>
);

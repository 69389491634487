import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Flex, Box, Image, Center, Button, Show, Hide } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { object, string, number, bool, date } from "yup";
import { FormikProvider, useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import eventBus from "../EventBus";

import {
  Input,
  PhoneInput,
  RadioButtonInput,
  PriceCardSlim,
  CheckboxInput,
} from "../components";

import { saveRegister } from "../api/references";

import {
  birthdateValidation,
  CHANGE_PLAN_EVENT,
  DEFAULT_VALUES,
  MAX_DATE_BIRTHDAY,
  MIN_DATE_BIRTHDAY,
  PLAN_OBJECTS,
  PLAN_TYPES,
  REGEX_PHONE_NUMBER,
} from "../constants";
import { capitalize } from "../utils";
import EVENTS, { Event } from "../events";

const { changePackage, sendForm, errorForm } = EVENTS;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Etc/GMT+6");

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || "";

const text = {
  inputs: {
    name: "Nombre",
    names: "Nombre(s)",
    surname1: "Apellido Paterno",
    surname2: "Apellido Materno",
    phone: "Teléfono",
    phone_placeholder: "0000000000",
    birthdate: "Fecha de nacimiento",
    cp: "Código postal",
    cp_placeholder: "00000",
    email: "Correo electrónico",
    email_placeholder: "usuario@correo.com",
    plan: "Plan",
    errors: {
      required: "Campo requerido",
      inputMinLegth: "Al menos 3 caracteres",
      number: "El campo debe ser numérico",
      phone: "Número de telefono inválido",
      cp: "Código Postal inválido ",
      terms: "Debe acertar los términos y condiciones",
      birthdate: "Fecha inválida",
    },
  },
  contract: "Contrata",
  accept: "Acepto los términos y condiciones",
  privacy:
    "Tu privacidad es importante para nosotros. Tenku utiliza la información que proporcionas para ponerse en contacto contigo en relación con contenido, productos y servicios relevantes para ti. Puedes darte de baja para dejar de recibir este tipo de comunicaciones en cualquier momento. Si deseas obtener más información sobre la protección de tus datos en Tenku, consulta nuestra Política de Privacidad.",
  notification: {
    success: {
      title: "Reistro completado",
      description: "Recibiras un mensaje con la referencia de pago",
    },
    error: {
      title: "Error al realizar el registro",
      description: "Por favor intentalo más tarde",
    },
  },
  loadingBtn: "Registrando...",
};

const plans = PLAN_OBJECTS.map(({ name, type }) => ({ name, type }));

export interface ContractProps {
  enterEvent?: Event;
  leaveEvent?: Event;
}

export const Contract = ({ enterEvent, leaveEvent }: ContractProps) => {
  const toast = useToast();

  const formSchema = object().shape({
    name: string()
      .min(3, text.inputs.errors.inputMinLegth)
      .required(text.inputs.errors.required),
    surname1: string()
      .min(3, text.inputs.errors.inputMinLegth)
      .required(text.inputs.errors.required),
    surname2: string()
      .min(3, text.inputs.errors.inputMinLegth)
      .required(text.inputs.errors.required),
    phone: string()
      .matches(REGEX_PHONE_NUMBER, text.inputs.errors.phone)
      .required(text.inputs.errors.required),
    cp: number()
      .typeError(text.inputs.errors.number)
      .min(1000, text.inputs.errors.cp)
      .max(99999, text.inputs.errors.cp)
      .required(text.inputs.errors.required),
    birthdate: date()
      .required()
      .test(
        "is-valid-birthdate",
        text.inputs.errors.birthdate,
        birthdateValidation
      ),
    email: string(),
    terms: bool().oneOf([true], text.inputs.errors.terms),
    recaptchaToken: string().required(""),
  });

  const formik = useFormik({
    initialValues: DEFAULT_VALUES,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const selectedPlan = useMemo(
    () => PLAN_TYPES[formik.values.type],
    [formik.values.type]
  );
  const onChangePlan = (value: string) => {
    formik.setFieldValue("type", value);
    changePackage({ value: value });
  };
  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    if (newDate) {
      formik.setFieldValue("birthdate", dayjs(newDate).toDate());
    }
  };

  const onChangeReCaptcha = (token: string | null) => {
    formik.setFieldValue("recaptchaToken", token);
  };

  const [isSaving, setIsSaving] = useState(false);
  const onClick = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    sendForm(formik.values);
    const { success, errorMessage } = await saveRegister({
      name: formik.values.name,
      surname1: formik.values.surname1,
      surname2: formik.values.surname2,
      phone: formik.values.phone,
      birthdate: formik.values.birthdate.toISOString(),
      cp: formik.values.cp,
      type: formik.values.type,
      email: formik.values.email,
    });

    if (success) {
      toast({
        ...text.notification.success,
        status: "success",
        duration: 3000,
        containerStyle: {
          backgroundColor: "green",
        },
      });
      formik.resetForm();
    } else {
      toast({
        ...text.notification.error,
        status: "error",
        duration: 3000,
      });
      console.error(errorMessage);
      errorForm({ error: errorMessage });
    }
    setIsSaving(false);
  };

  const onEventChangePlan = useCallback(
    (type: unknown) => {
      formik.setFieldValue("type", type as string);
      changePackage({ value: type as string });
    },
    [formik]
  );

  useEffect(() => {
    eventBus.on(CHANGE_PLAN_EVENT, onEventChangePlan);

    return () => {
      eventBus.off(CHANGE_PLAN_EVENT, onEventChangePlan);
    };
  }, [onEventChangePlan]);

  useEffect(() => {
    enterEvent?.();
    return () => {
      leaveEvent?.(formik.values);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onBlurInputEventGA = (name: string) => () => {
    const _name = name as keyof typeof formik.values;
    const capitalizeName = capitalize(name);

    let value = formik.values[_name];
    let event = EVENTS[`leave${capitalizeName}`];

    if (name === "birthdate") {
      value = (formik.values[_name] as Date).toISOString();
      event = EVENTS[`change${capitalizeName}`];
    }

    event?.({
      value,
    });
  };

  return (
    <Box
      display="flex"
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="center"
    >
      <FormikProvider value={formik}>
        <Flex
          w={["100%", 948]}
          minH={["100%", 636]}
          px={[4, 6]}
          py={[6, 10]}
          color="ivory"
          direction="column"
          borderRadius={14}
          boxShadow="0px 4px 50px -12px rgba(0, 0, 0, 0.12)"
        >
          <Box>
            <Center>
              <a href="/">
                <Image src="logo_2.png" paddingBottom={10} />
              </a>
            </Center>
          </Box>
          <Flex flexDirection={["column", "row"]} gap="3">
            <Box
              w="auto"
              display="flex"
              flexDirection="column"
              flexGrow={1}
              gap={2}
            >
              <Box display="flex" flexDirection={["column", "row"]}>
                <Box w={[null, "50%"]}>
                  <Input
                    id="name"
                    name="name"
                    label={text.inputs.name}
                    placeholder={text.inputs.names}
                    required
                    autoComplete="off"
                    onBlur={onBlurInputEventGA("name")}
                  />
                </Box>
                <Box w={[null, "25%"]}>
                  <Input
                    id="surname1"
                    name="surname1"
                    label={text.inputs.surname1}
                    placeholder={text.inputs.surname1}
                    autoComplete="off"
                    required
                    onBlur={onBlurInputEventGA("surname1")}
                  />
                </Box>
                <Box w={[null, "25%"]}>
                  <Input
                    id="surname2"
                    name="surname2"
                    label={text.inputs.surname2}
                    placeholder={text.inputs.surname2}
                    autoComplete="off"
                    required
                    onBlur={onBlurInputEventGA("surname2")}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={["column", "row"]}>
                <Box w={[null, "50%"]}>
                  <PhoneInput
                    id="phone"
                    name="phone"
                    label={text.inputs.phone}
                    placeholder={text.inputs.phone_placeholder}
                    maxLength={10}
                    autoComplete="off"
                    required
                    onBlur={onBlurInputEventGA("phone")}
                  />
                </Box>
                <Box w={[null, "50%"]} display="flex" flexDirection={["row"]}>
                  <Box w={["50%"]}>
                    <Input
                      id="birthdate"
                      name="birthdate"
                      type="date"
                      label={text.inputs.birthdate}
                      value={
                        formik.values.birthdate.toISOString().split("T")[0]
                      }
                      min={MIN_DATE_BIRTHDAY}
                      max={MAX_DATE_BIRTHDAY}
                      onChange={onChangeDate}
                      required
                      onBlur={onBlurInputEventGA("birthdate")}
                    />
                  </Box>
                  <Box w={["50%"]}>
                    <Input
                      id="cp"
                      name="cp"
                      label={text.inputs.cp}
                      placeholder={text.inputs.cp_placeholder}
                      maxLength={5}
                      autoComplete="off"
                      required
                      onBlur={onBlurInputEventGA("cp")}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Input
                  id="email"
                  name="email"
                  label={text.inputs.email}
                  placeholder={text.inputs.email_placeholder}
                  autoComplete="off"
                  forceHideErrors
                  onBlur={onBlurInputEventGA("email")}
                />
              </Box>
              <Box display="flex" flexDirection="row">
                <RadioButtonInput
                  name="type"
                  options={plans}
                  label={text.inputs.plan}
                  value={formik.values.type}
                  onChange={onChangePlan}
                  required
                />
              </Box>
              <Box>
                <ReCAPTCHA
                  sitekey={recaptchaKey.toString()}
                  onChange={onChangeReCaptcha}
                />
              </Box>

              <Hide above="md">
                <Box>
                  <PriceCardSlim {...selectedPlan} />
                </Box>
              </Hide>
              <Button
                type="submit"
                variant="primary"
                marginX="5px !important"
                marginTop="auto !important"
                marginBottom="0px !important"
                py={11}
                fontWeight={700}
                fontSize={18}
                borderBottom="1px solid"
                borderBottomColor="silver"
                isDisabled={!(formik.isValid && formik.dirty)}
                onClick={onClick}
                isLoading={isSaving}
                loadingText={text.loadingBtn}
              >
                {text.contract}
              </Button>
            </Box>
            <Show above="md">
              <Box w="220px" h="fit-content" paddingTop="22px">
                <PriceCardSlim {...selectedPlan} />
              </Box>
            </Show>
          </Flex>
          <Box py="11px">
            <CheckboxInput name="terms">
              <a href="/terms" target="_blank" referrerPolicy="no-referrer">
                {text.accept}
              </a>
            </CheckboxInput>
          </Box>
          <Box px={5} paddingTop={3} paddingBottom={0}>
            <Center
              color="black"
              fontSize={9}
              fontWeight={400}
              textAlign="center"
            >
              <a href="/terms" target="_blank" referrerPolicy="no-referrer">
                {text.privacy}
              </a>
            </Center>
          </Box>
        </Flex>
      </FormikProvider>
    </Box>
  );
};

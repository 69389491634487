import { extendTheme, theme as base } from "@chakra-ui/react"

const white = '#FFFFFF'
const ivory = '#F9FAFB'
const bright_gray = '#EFEFEF'
const platinum = '#E6E6E6'
const gainsboro = '#D9DBE1'
const silver = '#AFB5BB'
const spanish_gray = '#999999'
const gray = '#787878'
const black_olive = '#3C3C3B'
const maize = '#F7CA4A'
const primary = '#56DDB3'
const green = '#43D9AA'
const green_whatsapp = '#25D366'

const primary_green = {
  "50": "#EAFBF5",
  "100": "#C4F3E4",
  "200": "#9DEBD3",
  "300": "#77E4C2",
  "400": "#51DCB0",
  "500": "#2BD49F",
  "600": "#22AA7F",
  "700": "#1A7F5F",
  "800": "#115540",
  "900": "#092A20"
}
const secondary = '#234072'
const dark_blue = '#00274C'
const blue = '#192A48'

const theme = extendTheme({
  ...base,
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
  },
  colors: {
    white,
    ivory,
    "bright-gray": bright_gray,
    platinum,
    gainsboro,
    silver,
    "spanish-gray": spanish_gray,
    gray,
    "black-olive": black_olive,
    maize,
    primary,
    green,
    "primary-green": primary_green,
    secondary,
    "dark-blue": dark_blue,
    blue,
    "green-whatsapp": green_whatsapp,
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: primary,
          color: white,
          _disabled: {
            backgroundColor: black_olive,
            cursor: 'not-allowed',
          },
          _hover: {
            _disabled: {
              backgroundColor: black_olive,
            }
          }
        },
        'secondary': {
          bg: secondary,
          color: green,
          fontSize: 18,
          fontWeight: 700
        },
        'tertiary': {
          bg: white,
          color: blue,
        },
        'ghost': {
          color: white,
          fontSize: 19,
          fontWeight: 500,
          _hover: {
            bg: 'none',
            textDecoration: 'none',
          },
        }
      }
    },
    Checkbox: {
      variants:{
        micro: {
          icon: {
            color: 'white',
          },
          control: {
            border: '1px',
            borderColor: 'black',
            borderRadius: 'base',
            _disabled: {
              borderColor: 'black',
              bg: 'black',
            },
          },
          label: {
            color: 'black',
            fontSize: '11px',
            fontWeight: '700',
          },
        }
      },
    }
  }
})

export default theme

import * as React from "react";
import { ColorModeScript } from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const GA_TAG = process.env.REACT_APP_GA_TAG || "";
const STAGE = process.env.REACT_APP_STAGE || "DEV";
const FB_PIXEL_TAG = process.env.REACT_APP_FB_TAG || "";
const AVAILABLE_STAGES = ["sandbox", "production"];

if (AVAILABLE_STAGES.includes(STAGE)) {
  if (GA_TAG !== "") {
    ReactGA.initialize(GA_TAG);
  }

  if (FB_PIXEL_TAG !== "") {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(FB_PIXEL_TAG, undefined, options);
    ReactPixel.pageView();
  }
}

root.render(
  <BrowserRouter>
    <ColorModeScript />
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from "react";
import {
  Flex,
  Text,
  Box,
  Image,
  Center,
  VStack,
  Divider,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-scroll";

import eventBus from "../EventBus";

import { CHANGE_PLAN_EVENT } from "../constants";

import { planType } from "../types/shared";

export interface PriceCardProps {
  type: planType;
  price: number;
  accidents: number;
  losts: number;
  dead: number;
  deductible: number;
}

export const PriceCard = ({
  type,
  price,
  accidents,
  losts,
  dead,
  deductible,
}: PriceCardProps) => {
  const bg = `images/plans/${type.toLowerCase()}/bg_card.png`;
  const logo = `images/plans/${type.toLowerCase()}/logo.png`;

  const text = {
    annual: "Costo Anual",
    accidents: "Accidentes personales",
    losts: "Pérdidas orgánicas",
    dead: "Seguro de vida por muerte accidental",
    deductible: "*deducible",
    contract: "contratar",
  };

  const onClickHandler = () => eventBus.emit(CHANGE_PLAN_EVENT, type);

  return (
    <Box
      w="385px"
      h="600px"
      borderWidth="1px"
      borderRadius="17px"
      overflow="hidden"
      bgColor="bright-gray"
      color="white"
    >
      <Box h="269px" sx={{ background: `url(${bg})` }} color="white">
        <Flex alignItems="center" direction="column">
          <Image py="29px" src={logo} />
          <Flex w="100%" h="90px" paddingTop="4">
            <Box
              display="flex"
              flex="1"
              justifyContent="flex-end"
              justifyItems="flex-end"
            >
              <Text fontSize="31px" w="32px" fontWeight="extrabold" as="span">
                $
              </Text>
            </Box>
            <Center>
              <Text fontSize="91px" fontWeight="extrabold">
                {price.toLocaleString()}
              </Text>
            </Center>
            <Box display="flex" flex="1" alignItems="flex-end">
              <Text fontSize="31px" fontWeight="extrabold">
                mxn
              </Text>
            </Box>
          </Flex>
          <Text paddingTop="4px" fontWeight="semibold" fontSize="14px">
            {text.annual}
          </Text>
        </Flex>
      </Box>
      <Box display="flex" flexDirection="column">
        <VStack paddingTop="30px" px="48px">
          <Text fontSize="20px" fontWeight="600" color="black">
            ${accidents.toLocaleString()}mxn
          </Text>
          <Text
            sx={{ marginTop: "-8px !important" }}
            fontSize="14px"
            fontWeight="400"
            color="gray"
          >
            {text.accidents}
          </Text>
          <Divider color="gray" />
          <Text fontSize="20px" fontWeight="600" color="black">
            ${losts.toLocaleString()}mxn
          </Text>
          <Text
            sx={{ marginTop: "-8px !important" }}
            fontSize="14px"
            fontWeight="400"
            color="gray"
          >
            {text.losts}
          </Text>
          <Divider color="gray" />
          <Text fontSize="20px" fontWeight="600" color="black">
            ${dead.toLocaleString()}mxn
          </Text>
          <Text
            sx={{ marginTop: "-8px !important" }}
            fontSize="14px"
            fontWeight="400"
            color="gray"
          >
            {text.dead}
          </Text>
          <Divider color="gray" />
          <Text fontSize="14px" fontWeight="400" color="gray">
            {text.deductible} ${deductible.toLocaleString()}mxn
          </Text>
        </VStack>
        <Box paddingTop="18px" display="flex" alignSelf="center">
          <Button
            h="50px"
            p="14px 46px"
            variant="primary"
            borderRadius="20px"
            boxShadow="0px 4px 4px 0px #00000040"
            fontWeight="700"
            fontSize="18px"
          >
            <Link to="contract" smooth={true} onClick={onClickHandler}>
              {text.contract}
            </Link>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

import * as React from "react";
import {
  Input as InputBase,
  Text,
  VStack,
  InputProps as InputPropsBase,
} from "@chakra-ui/react";
import { useField } from "formik";
import { ErrorMessage } from "./ErrorMessage";

export interface InputProps extends InputPropsBase {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  forceHideErrors?: boolean;
}

export const Input = ({
  label,
  required,
  placeholder,
  forceHideErrors = false,
  ...props
}: InputProps) => {
  const [field, meta] = useField(props.name);

  return (
    <VStack p="5px" maxWidth="100%">
      <Text
        color="black"
        h={4}
        fontSize={11}
        fontWeight={700}
        alignSelf="flex-start"
      >
        {label}
        {label && required && <>*</>}
      </Text>
      <InputBase
        w="100%"
        _placeholder={{
          color: "spanish-gray",
          fontSize: "14px",
          fontWeight: "700",
        }}
        placeholder={placeholder}
        marginTop="3px !important"
        color="black"
        fontSize={14}
        fontWeight={700}
        backgroundColor="platinum"
        borderBottom="1px solid"
        borderBottomColor="silver"
        borderRadius={10}
        border={meta.touched && meta.error ? "1px solid red" : "none"}
        focusBorderColor="none"
        {...field}
        {...props}
      />
      {!forceHideErrors && <ErrorMessage name={props.name} />}
    </VStack>
  );
};

import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import {
  Box,
  Image,
  Flex,
  Spacer,
  Button,
  Stack,
  Hide,
  Show,
} from "@chakra-ui/react";
import "./Header.css";

export const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const text = {
    home: "INICIO",
    discover: "DESCUBRE TENKU",
    memberships: "MEMBRESÍAS",
    btn: "CONTRATA AQUÍ",
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop >= 106);
  };

  return (
    <Box
      py={["3", "4"]}
      h={["65px", "72px"]}
      position="fixed"
      w="100%"
      bg={isFixed ? "green" : "transparent"}
      as="header"
      zIndex={999}
      transition="all 0.2s ease-in-out"
    >
      <Box m="auto" width={{ "2xl": "1440px" }} px={[2, null]}>
        <Flex>
          <a href="/">
            <Image w={105} src="logo.png" alt="logo" />
          </a>
          <Spacer />

          {/* Mobile menu */}
          <Hide above="lg">
            <Button
              size="lg"
              variant="ghost"
              px={0}
              color={isFixed ? "white" : "dark-blue"}
              fontSize={16}
            >
              <Link to="contract" smooth spy hashSpy>
                {text.btn}
              </Link>
            </Button>
          </Hide>
          {/* Mobile menu */}

          <Show above="lg">
            <Stack spacing="2" direction="row">
              <Button size="md" variant="ghost">
                <Link to="home" smooth spy hashSpy>
                  {text.home}
                </Link>
              </Button>
              <Button size="md" variant="ghost">
                <Link to="discover" smooth spy hashSpy>
                  {text.discover}
                </Link>
              </Button>
              <Button size="md" variant="ghost">
                <Link to="membership" smooth spy hashSpy>
                  {text.memberships}
                </Link>
              </Button>
              <Button
                size="md"
                variant={isFixed ? "tertiary" : "secondary"}
                sx={{
                  boxShadow: isFixed ? "" : "0px 4px 4px 0px #00000040",
                }}
              >
                <Link to="contract" smooth spy hashSpy>
                  {text.btn}
                </Link>
              </Button>
            </Stack>
          </Show>
        </Flex>
      </Box>
    </Box>
  );
};

import * as React from "react";
import { InputProps as InputPropsBase, Checkbox } from "@chakra-ui/react";
import { ErrorMessage, useField } from "formik";

export interface CheckboxInputProps extends InputPropsBase {
  name: string;
  children: React.ReactNode | React.ReactNode[];
}

export const CheckboxInput = ({ children, ...props }: CheckboxInputProps) => {
  const [field] = useField({ name: props.name, type: "checkbox" });
  return (
    <>
      <Checkbox
        colorScheme="primary-green"
        variant="micro"
        color="black"
        fontSize="sm"
        fontWeight={700}
        defaultChecked
        {...field}
      >
        {children}
      </Checkbox>
      <ErrorMessage name={props.name} component="span" />
    </>
  );
};

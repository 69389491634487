import * as React from "react";
import { Box, Center, Flex, Image, Spacer, Text, Icon } from "@chakra-ui/react";
import { Link } from "react-scroll";
import { SOCIAL_LINKS } from "../constants";
import { FiInstagram, FiFacebook } from "react-icons/fi";

const text = {
  copyright: `Copyright © ${new Date().getFullYear()} Tenku`,
  allRights: "All rights reserved",
  tenku: "Tenku",
  home: "Inicio",
  discover: "Descubre tenku",
  membership: "Membresías",
  report: "Reportar accidente",
  contact: "Contacto",
  support: "Soporte",
  faq: "F.A.Q",
  terms: "Términos y condiciones",
  legal: "Legal",
  privacy: "Aviso de privacidad",
  suscribe: "Suscribete",
  email: "Correo electrónico",
};

export const OldFooter = () => {
  return (
    <Box w="100%" as="footer" py="3">
      <Center>
        Copyright &copy; Tenkuseguros {new Date().getFullYear()}.{" "}
        <a href="Terminos-y-Condiciones.pdf" target="_blank">
          Terminos y Condiciones
        </a>
      </Center>
    </Box>
  );
};

export const Footer = () => {
  return (
    <Flex
      w="100%"
      minHeight="360px"
      as="footer"
      px={16}
      py={16}
      bgColor="primary"
      direction={{ base: "column", md: "row" }}
    >
      <Spacer />
      <Flex
        h="210px"
        flexDirection="column"
        py={3}
        gap={10}
        marginBottom={{ base: 10, md: 0 }}
      >
        <Image src="footer_logo.png" alt="logo" h={10} w={32} p={{}} />
        <Box>
          <Text
            color="dark-blue"
            fontSize={14}
            fontWeight={400}
            paddingBottom={1}
          >
            {text.copyright}
          </Text>
          <Text color="dark-blue" fontSize={14} fontWeight={400} paddingTop={1}>
            {text.allRights}
          </Text>
        </Box>
        <Flex gap={4}>
          <a
            href={SOCIAL_LINKS.INSTAGRAM}
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
          >
            <Center w="32px" h="32px">
              <Icon as={FiInstagram} color="white" w="20px" h="20px" />
            </Center>
          </a>
          <a
            href={SOCIAL_LINKS.FACEBOOK}
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
          >
            <Center w="32px" h="32px">
              <Icon as={FiFacebook} color="white" w="20px" h="20px" />
            </Center>
          </a>
        </Flex>
      </Flex>
      <Spacer />
      <Flex gap="30px" direction={{ base: "column", md: "row" }}>
        <Box w={40}>
          <Text
            color="dark-blue"
            fontSize={20}
            fontWeight={500}
            paddingTop={1}
            paddingBottom={3}
          >
            {text.tenku}
          </Text>
          <Link to="home" smooth spy hashSpy>
            <Text
              color="dark-blue"
              fontSize={16}
              fontWeight={400}
              py={"6px"}
              cursor="pointer"
            >
              {text.home}
            </Text>
          </Link>
          <Link to="discover" smooth spy hashSpy>
            <Text
              color="dark-blue"
              fontSize={16}
              fontWeight={400}
              py={"6px"}
              cursor="pointer"
            >
              {text.discover}
            </Text>
          </Link>
          <Link to="membership" smooth spy hashSpy>
            <Text
              color="dark-blue"
              fontSize={16}
              fontWeight={400}
              py={"6px"}
              cursor="pointer"
            >
              {text.membership}
            </Text>
          </Link>
          <a href={SOCIAL_LINKS.ACCIDENT}>
            <Text
              color="dark-blue"
              fontSize={16}
              fontWeight={400}
              py={"6px"}
              cursor="pointer"
            >
              {text.report}
            </Text>
          </a>
          <a href={SOCIAL_LINKS.CONTACT}>
            <Text
              color="dark-blue"
              fontSize={16}
              fontWeight={400}
              py={"6px"}
              cursor="pointer"
            >
              {text.contact}
            </Text>
          </a>
        </Box>
        <Box w={40}>
          <Text
            color="dark-blue"
            fontSize={20}
            fontWeight={500}
            paddingTop={1}
            paddingBottom={3}
            cursor="pointer"
          >
            {text.support}
          </Text>
          <Text
            color="dark-blue"
            fontSize={16}
            fontWeight={400}
            py={"6px"}
            cursor="pointer"
          >
            <a href="/terms" referrerPolicy="no-referrer">
              {text.terms}
            </a>
          </Text>
          <Text
            color="dark-blue"
            fontSize={16}
            fontWeight={400}
            py={"6px"}
            cursor="pointer"
          >
            <a href="/terms" referrerPolicy="no-referrer">
              {text.legal}
            </a>
          </Text>
          <Text
            color="dark-blue"
            fontSize={16}
            fontWeight={400}
            py={"6px"}
            cursor="pointer"
          >
            <a href="/terms" referrerPolicy="no-referrer">
              {text.privacy}
            </a>
          </Text>
        </Box>
        {/* <Box w={40}>
          <Text
            color="dark-blue"
            fontSize={20}
            fontWeight={500}
            paddingTop={1}
            paddingBottom={3}
            cursor="pointer"
          >
            {text.suscribe}
          </Text>
          <InputGroup color="white" bg="white" opacity={0.2} borderRadius={8}>
            <Input
              w="250px"
              fontSize={14}
              color="white"
              placeholder={text.email}
              opacity={1}
              _placeholder={{
                color: "gainsboro",
                fontSize: "14px",
              }}
            />
            <InputRightElement children={<FiSend color="white" />} />
          </InputGroup>
        </Box> */}
      </Flex>
      <Spacer />
    </Flex>
  );
};

import * as React from "react";
import {
  Input as InputBase,
  Text,
  VStack,
  InputProps as InputPropsBase,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useField } from "formik";
import { ErrorMessage } from "./ErrorMessage";
// import { ChevronDownIcon } from "@chakra-ui/icons";

export interface InputProps extends InputPropsBase {
  name: string;
  label: string;
  required?: boolean;
  placeholder: string;
}

export const PhoneInput = ({
  label,
  required,
  placeholder,
  ...props
}: InputProps) => {
  const [field, meta] = useField(props.name);
  return (
    <VStack p="5px" maxWidth="100%">
      <Text color="black" fontSize={11} fontWeight={700} alignSelf="flex-start">
        {label}
        {required && <>*</>}
      </Text>
      <InputGroup marginTop="0px !important">
        <InputLeftElement
          children={<>+52</>}
          p={3}
          w={14}
          bgColor="green"
          marginTop="3px !important"
          borderLeftRadius="10px"
          fontSize={18}
          fontWeight={700}
        />
        <InputBase
          w="100%"
          _placeholder={{
            color: "spanish-gray",
            fontSize: "14px",
            fontWeight: "700",
          }}
          placeholder={placeholder}
          marginTop="3px !important"
          color="black"
          fontSize={14}
          fontWeight={700}
          paddingLeft={16}
          backgroundColor="platinum"
          borderBottom="1px solid"
          borderBottomColor="silver"
          borderRadius={10}
          border={meta.touched && meta.error ? "1px solid red" : "none"}
          focusBorderColor="none"
          {...field}
          {...props}
        />
      </InputGroup>
      <ErrorMessage name={props.name} />
    </VStack>
  );
};

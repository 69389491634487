import * as React from "react";
import { Flex, Text, Box, Grid } from "@chakra-ui/react";

import { PriceCard } from "../components/PriceCard";

import { PLAN_OBJECTS } from "../constants";

export const Membership = () => {
  const text = {
    title: "ELIGE TU MEMBRESÍA",
    text: "¡Protégete a ti y a tu familia hoy mismo con nuestras membresías de accidentes personales! Elige entre nuestras 3 membresías con coberturas diseñadas de acuerdo a tus necesidades. No esperes más, ¡actúa ahora y asegura tu tranquilidad!",
  };

  return (
    <Box>
      <Flex paddingTop={10} direction="column" alignItems="center">
        <Text
          fontWeight="800"
          color="dark-blue"
          fontSize={[40, 49]}
          lineHeight="60px"
          letterSpacing="0.105em"
          align={["center", null]}
        >
          {text.title}
        </Text>
        <Text
          paddingTop={2}
          maxW={[null, "70%"]}
          color="black"
          align="center"
          fontSize={19}
          fontWeight={400}
          letterSpacing="0.105em"
        >
          {text.text}
        </Text>
        <Box
          maxW="100%"
          overflowX="auto"
          marginLeft="auto"
          marginRight="auto"
          justifyContent="center"
        >
          <Grid
            paddingTop="78px"
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(3, minmax(385px, 1fr))",
            }}
            gap={{ base: 5, "2xl": 30 }}
          >
            {PLAN_OBJECTS.map((item, index) => (
              <PriceCard {...item} key={index} />
            ))}
          </Grid>
        </Box>
      </Flex>
    </Box>
  );
};

import * as React from "react";
import {
  Box,
  useRadio,
  UseRadioProps,
  useRadioGroup,
  Text,
  Flex,
  Center,
} from "@chakra-ui/react";

interface RadioButtonProps extends UseRadioProps {
  children: React.ReactNode;
}

const RadioButton = (props: RadioButtonProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <Box
        {...checkbox}
        px={5}
        py={3}
        color="spanish-gray"
        fontSize={14}
        fontWeight={700}
        bg="platinum"
        borderRadius="10px"
        borderBottom="1px solid"
        borderBottomColor="silver"
        cursor="pointer"
        _checked={{
          bg: "green",
          color: "white",
          borderColor: "primary-green.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
      >
        <Center>{props.children}</Center>
      </Box>
    </Box>
  );
};

export interface RadioButtonInputProps {
  name: string;
  options: { name: string; type: string }[];
  value: string;
  label: string;
  required?: boolean;
  onChange?: (value: string) => void;
}

export const RadioButtonInput = ({
  name,
  options,
  value,
  label,
  required,
  onChange,
}: RadioButtonInputProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value: value,
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <Box p="5px" w="100%">
      <Text color="black" fontSize={11} fontWeight={700} alignSelf="flex-start">
        {label}
        {required && <>*</>}
      </Text>
      <Flex {...group} gap={5}>
        {options.map(({ type, name }) => {
          const radio = getRadioProps({ value: type });
          return (
            <RadioButton key={type} {...radio}>
              {name}
            </RadioButton>
          );
        })}
      </Flex>
    </Box>
  );
};

import React from "react";
import { IconButton, Icon, Link } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";

type position = "bottom-right" | "bottom-left" | "top-right" | "top-left";

interface WhatsappButtonProps {
  phoneNumber: string;
  message?: string;
  position?: position;
}

const WhatsappButton = ({
  phoneNumber,
  message = "",
  position = "bottom-right",
}: WhatsappButtonProps) => {
  const encodedMessage = encodeURIComponent(message);

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  const [positionY, positionX] = position.split("-");
  const positionProps = {
    [positionY]: "20px",
    [positionX]: "20px",
  };

  return (
    <Link
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      position="fixed"
      zIndex={9999}
      {...positionProps}
    >
      <IconButton
        icon={<Icon as={FaWhatsapp} fontSize={30} />}
        borderRadius="50%"
        width="60px"
        height="60px"
        backgroundColor="green-whatsapp"
        color="white"
        aria-label="WhatsApp message"
        _hover={{
          backgroundColor: "platinum",
        }}
      />
    </Link>
  );
};

export { WhatsappButton };

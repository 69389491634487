import * as React from "react";
import { Box } from "@chakra-ui/react";

import { Contract } from "../sections";
import { Footer, WhatsappButton } from "../components";
import { WHATSAPP_CTA } from "../constants";
import EVENTS from "../events";

const { enterForm, leaveForm } = EVENTS;

const sectionProps = {
  m: "auto",
  w: "100%",
  height: { base: "fit-content", large: "calc(100vh - 48px)" },
  display: { base: "initial", large: "block" },
};

export const Register = () => (
  <Box>
    <Box {...sectionProps} id="contract" paddingBottom={4}>
      <Contract enterEvent={enterForm} leaveEvent={leaveForm} />
    </Box>
    <Footer />
    <WhatsappButton
      phoneNumber={WHATSAPP_CTA.NUMBER}
      message={WHATSAPP_CTA.MESSAGE}
      position="bottom-right"
    />
  </Box>
);

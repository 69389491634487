import * as React from "react";
import { Box } from "@chakra-ui/react";
import { ErrorMessage as ErrorFormik } from "formik";

export interface ErrorMessageProps {
  name: string;
}

export const ErrorMessage = ({ name }: ErrorMessageProps) => {
  return (
    <Box
      w="100%"
      h="2"
      display="flex"
      marginTop="3px !important"
      color="red"
      fontSize={10}
      fontWeight={800}
    >
      <ErrorFormik name={name} component="span" />
    </Box>
  );
};

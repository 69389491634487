import { AddReference, planType } from "../types/shared";

const API_URI = process.env.REACT_APP_API_URI || ''

interface RegisterRequest {
    name: string;
    surname1: string;
    surname2: string;
    phone: string;
    birthdate: string;
    type: planType;
    cp: number;
    email: string;
}
interface SuccessResponse {
    reference: string,
    fullName: string,
    amount: number,
    status: string,
    type: string
}

interface Response {
    success: boolean,
    data?: SuccessResponse,
    errorMessage: unknown
}

const saveRegister = async (form: AddReference): Promise<Response> => {
    const _form: RegisterRequest = {
        ...form,
        cp: Number(form.cp),
        birthdate: new Date(form.birthdate).toISOString()
    }
    try {
        const response = await fetch(
            `${API_URI}/reference`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(_form)
            }
        ).then((response) => response.json());

        return {
            success: true,
            errorMessage: '',
            data: response
        }
    } catch (e) {
        return {
            success: false,
            errorMessage: e,
            data: undefined
        }
    }
}

export {
    saveRegister
}

import * as React from "react";
import {
  Flex,
  Text,
  Box,
  Image,
  Center,
  VStack,
  Divider,
} from "@chakra-ui/react";

import { planType } from "../types/shared";
import { PLAN_TYPES } from "../constants";

const config = {
  [PLAN_TYPES.BASIC.type]: {
    bg: "green",
    coverage: {
      sx: { marginTop: "6px !important" },
      fontSize: "14px",
      fontWeight: "700",
      color: "white",
    },
    subtext: {
      fontSize: "9px",
      fontWeight: "400",
      color: "white",
      align: "center" as const,
    },
    separator: {
      color: "white",
    },
    deductible: {
      fontSize: "9px",
      fontWeight: "600",
      color: "white",
    },
    cost: {
      dollar: {
        fontSize: "24px",
        fontWeight: "700",
        color: "white",
      },
      value: {
        fontSize: "49px",
        fontWeight: "700",
        color: "dark-blue",
      },
      currency: {
        fontSize: "11px",
        fontWeight: "700",
        color: "white",
      },
      annual: {
        fontSize: "12px",
        fontWeight: "400",
        color: "white",
        align: "center" as const,
      },
    },
  },
  [PLAN_TYPES.PLUS.type]: {
    bg: "black-olive",
    coverage: {
      sx: { marginTop: "6px !important" },
      fontSize: "14px",
      fontWeight: "700",
      color: "white",
    },
    subtext: {
      fontSize: "9px",
      fontWeight: "400",
      color: "white",
      align: "center" as const,
    },
    separator: {
      color: "white",
    },
    deductible: {
      fontSize: "9px",
      fontWeight: "600",
      color: "white",
    },
    cost: {
      dollar: {
        fontSize: "24px",
        fontWeight: "700",
        color: "white",
      },
      value: {
        fontSize: "49px",
        fontWeight: "700",
        color: "green",
      },
      currency: {
        fontSize: "11px",
        fontWeight: "700",
        color: "white",
      },
      annual: {
        fontSize: "12px",
        fontWeight: "400",
        color: "white",
        align: "center" as const,
      },
    },
  },
  [PLAN_TYPES.PRO.type]: {
    bg: "maize",
    coverage: {
      sx: { marginTop: "6px !important" },
      fontSize: "14px",
      fontWeight: "700",
      color: "white",
    },
    subtext: {
      fontSize: "9px",
      fontWeight: "400",
      color: "white",
      align: "center" as const,
    },
    separator: {
      color: "white",
    },
    deductible: {
      fontSize: "9px",
      fontWeight: "600",
      color: "white",
    },
    cost: {
      dollar: {
        fontSize: "24px",
        fontWeight: "700",
        color: "white",
      },
      value: {
        fontSize: "49px",
        fontWeight: "700",
        color: "dark-blue",
      },
      currency: {
        fontSize: "11px",
        fontWeight: "700",
        color: "white",
      },
      annual: {
        fontSize: "12px",
        fontWeight: "400",
        color: "white",
        align: "center" as const,
      },
    },
  },
};

export interface PriceCardSlimProps {
  type: planType;
  price: number;
  accidents: number;
  losts: number;
  dead: number;
  deductible: number;
}

export const PriceCardSlim = ({
  type,
  price,
  accidents,
  losts,
  dead,
  deductible,
}: PriceCardSlimProps) => {
  const logo = `images/plans/${type.toLowerCase()}/logo.png`;

  const text = {
    accidents: "Accidentes personales",
    losts: "Pérdidas orgánicas",
    dead: "Seguro de vida por muerte accidental",
    deductible: "*deducible",
    annual: "Pago anual",
  };

  const getConfig = React.useMemo(() => config[type], [type]);

  return (
    <Box
      w="100%"
      h="100%"
      borderWidth="1px"
      borderRadius="17px"
      overflow="hidden"
      bgColor={config[type].bg}
    >
      <Box display="flex" flexDirection="column" paddingBottom={[6, null]}>
        <VStack paddingTop={6} paddingBottom={3} px={7} gap={1}>
          <Image src={logo} paddingBottom={3} />
          <Text {...getConfig.coverage}>${accidents.toLocaleString()}mxn</Text>
          <Text sx={{ marginTop: "-5px !important" }} {...getConfig.subtext}>
            {text.accidents}
          </Text>
          <Divider {...getConfig.separator} />
          <Text {...getConfig.coverage}>${losts.toLocaleString()}mxn</Text>
          <Text sx={{ marginTop: "-5px !important" }} {...getConfig.subtext}>
            {text.losts}
          </Text>
          <Divider {...getConfig.separator} />
          <Text {...getConfig.coverage}>${dead.toLocaleString()}mxn</Text>
          <Text sx={{ marginTop: "-5px !important" }} {...getConfig.subtext}>
            {text.dead}
          </Text>
          {/* <Divider {...getConfig.separator} /> */}
          <Text {...getConfig.deductible}>
            {text.deductible} ${deductible.toLocaleString()}mxn
          </Text>
        </VStack>
        <Flex w="100%" h="44px">
          <Box
            display="flex"
            flex="1"
            justifyContent="flex-end"
            justifyItems="flex-end"
          >
            <Text as="span" {...getConfig.cost.dollar}>
              $
            </Text>
          </Box>
          <Center>
            <Text {...getConfig.cost.value}>{price.toLocaleString()}</Text>
          </Center>
          <Box display="flex" flex="1" alignItems="flex-end">
            <Text {...getConfig.cost.currency}>mxn</Text>
          </Box>
        </Flex>
        <Text {...getConfig.cost.annual}>{text.annual}</Text>
      </Box>
    </Box>
  );
};

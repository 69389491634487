import * as React from "react";
import { Box, Text } from "@chakra-ui/react";

import { Header, Footer, WhatsappButton } from "../components";
import { Landing, Discover, Membership, Contract } from "../sections";
import { WHATSAPP_CTA } from "../constants";
import EVENTS from "../events";

const { enterLanding, leaveLanding } = EVENTS;

export const HeaderSeparator = () => <Box m="auto" h="72px" w="100%" />;

const sectionProps = {
  m: "auto",
  w: "100%",
  minHeight: "100vh",
  maxWidth: "1440px",
  paddingTop: "72px",
  paddingLeft: [2, null],
  paddingRight: [2, null],
};

const text = {
  title: "ESTÁS A UN PASO DE ESTAR PROTEGIDO",
  description:
    "Completa estos sencillos datos, al finalizar sólo da click en contrata y en unos minutos recibirás en tu celular las instrucciones para realizar el pago en cualquier tienda Kiosko. ",
  cta: "¡No esperes más y regístrate hoy mismo!",
};

export const Home = () => (
  <Box
    bgColor="white"
    bgSize={["150% auto", "100% auto"]}
    bgPosition={["center top", "initial"]}
    bgImage='url("bg.png") !important'
    bgRepeat="no-repeat"
    minH="100vh"
    minW="100wh"
  >
    <Header />
    <Box {...sectionProps} h="100vh" id="home">
      <Landing />
    </Box>
    <Box
      {...sectionProps}
      width={{ base: "100%", "2xl": "1620px" }}
      display={{ base: "initial", "2xl": "block" }}
      id="discover"
    >
      <Discover />
    </Box>
    <HeaderSeparator />
    <Box
      {...sectionProps}
      display={{ base: "initial", "2xl": "block" }}
      id="membership"
    >
      <Membership />
    </Box>
    <HeaderSeparator />
    <Box {...sectionProps} display={{ base: "initial" }} id="contract">
      <Text
        fontWeight="800"
        color="dark-blue"
        fontSize={{ base: 25, "2xl": 40 }}
        lineHeight="60px"
        letterSpacing="0.105em"
        align="center"
      >
        {text.title}
      </Text>
      <Text
        py={2}
        px={{ base: 12, xl: 32 }}
        color="black"
        align="center"
        fontSize={{ base: 14, "2xl": 18 }}
        fontWeight={400}
        letterSpacing="0.105em"
      >
        {text.description}
        <Text as="b">{text.cta}</Text>
      </Text>
      <Contract enterEvent={enterLanding} leaveEvent={leaveLanding} />
    </Box>
    <Footer />
    <WhatsappButton
      phoneNumber={WHATSAPP_CTA.NUMBER}
      message={WHATSAPP_CTA.MESSAGE}
      position="bottom-right"
    />
  </Box>
);

import * as React from "react";
import { Flex, Text, VStack, Box, Image, AspectRatio } from "@chakra-ui/react";
import { VIDEO_SRC } from "../constants";

export const Discover = () => {
  const text = {
    title: "Descubre Tenku",
    subtitle: "LA FORMA MÁS SENCILLA DE PROTEGERTE",
    text1:
      "Somos una compañía 100% Mexicana enfocada en brindar protección contra accidentes personales, ofrecemos 3 tipos de membresías con precios accesibles, cuidando de tu familia y tu bolsillo.",
    text2:
      "Lo mejor de todo es que el proceso de contratación es simple y sin complicaciones, olvídate de los trámites burocráticos.",
    sections: [
      {
        img: "./images/point.png",
        title: "28 Años de experiencia",
        text: "Contamos con el respaldo de Orbe Seguros y Fianzas, líderes en el ramo asegurador desde hace 28 años, quienes en alianza con Thona Seguros nos permiten ofrecerte productos pensados para ti.",
      },
      {
        img: "./images/clock.png",
        title: "Protección total",
        text: "Nuestras membresías Tenku te protegen contra accidentes personales donde te ayudan a solventar los gastos derivados de cualquier incidente. Todas nuestras membresías incluyen una cobertura de seguro de vida por muerte accidental, así tu familia estará protegida ante cualquier eventualidad.",
      },
    ],
  };

  return (
    <>
      <Flex paddingTop={[10]} flexDirection={{ base: "column", md: "row" }}>
        <Image
          boxSize={{ base: "100%", md: "50%", "2xl": "893px" }}
          src="./images/phone.png"
          alt="phone"
        />
        <Box>
          <VStack paddingTop={{ "2xl": 50 }} paddingX={4}>
            <Text
              fontWeight="bold"
              color="blue"
              fontSize={[40, 53]}
              align={["center", null]}
            >
              {text.title}
            </Text>
            <Text
              fontWeight="bold"
              color="blue"
              fontSize={18}
              marginTop={[null, "-20px !important"]}
              align={["center", null]}
            >
              {text.subtitle}
            </Text>
            <Text paddingTop={4} fontWeight={500} fontSize={19} color="black">
              {text.text1}
              <br />
              {text.text2}
            </Text>
            {text.sections.map(({ img, title, text }, index) => (
              <Flex key={`section-${index}`}>
                <Image objectFit="none" src={img} alt={`section-${index}`} />
                <Flex p={4} direction="column">
                  <Text fontWeight="bold" fontSize={29} color="black">
                    {title}
                  </Text>
                  <Text fontWeight={500} fontSize={19} color="black">
                    {text}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </VStack>
        </Box>
      </Flex>
      <Box p={[null, 20]}>
        <AspectRatio
          ratio={16 / 9}
          maxWidth="80vw"
          maxHeight="80vh"
          margin="0 auto"
        >
          <iframe title="tenku" src={VIDEO_SRC} allowFullScreen />
        </AspectRatio>
      </Box>
    </>
  );
};

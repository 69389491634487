import * as React from "react";
import { Flex, Button, Stack, Text, ButtonGroup } from "@chakra-ui/react";
import { Link } from "react-scroll";

export const Landing = () => {
  const text = {
    home: "INICIO",
    discover: "DESCUBRE TENKU",
    description: `Nuestras membresías TENKU te protegen contra accidentes personales, ayudándote a solventar los gastos relacionados.`,
    btn: "CONTRATA AQUÍ",
    title: ["Un ", "Seguro", "a tu alcance"],
  };

  return (
    <Flex h="100%" alignItems="center">
      <Stack
        direction="column"
        w={["100%", "600px"]}
        marginTop={["auto"]}
        marginBottom={[12, "auto"]}
      >
        <Text
          fontWeight="extrabold"
          color={{ base: "blue", xl: "white" }}
          fontSize={[50, 90]}
        >
          {text.title[0]}
          <Text color="blue" as="span">
            {text.title[1]}
          </Text>
        </Text>
        <Text
          fontWeight="extrabold"
          color={{ base: "blue", xl: "white" }}
          fontSize={[50, 90]}
          marginTop="-25px !important"
        >
          {text.title[2]}
        </Text>

        <Text
          fontWeight={500}
          fontSize={19}
          color={{ base: "blue", xl: "white" }}
        >
          {text.description}
        </Text>
        <ButtonGroup>
          <Button size="lg" fontSize={21} variant="secondary">
            <Link to="contract" smooth={true}>
              {text.btn}
            </Link>
          </Button>
        </ButtonGroup>
      </Stack>
    </Flex>
  );
};

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { form } from "./types/shared";

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Etc/GMT+6')

const PLAN_TYPES = {
    BASIC: {
        type: "BASIC" as const,
        name: "Básico",
        price: 230,
        accidents: 30_000,
        losts: 30_000,
        dead: 30_000,
        deductible: 300,
    },
    PLUS: {
        type: "PLUS" as const,
        name: "Plus",
        price: 450,
        accidents: 50_000,
        losts: 100_000,
        dead: 100_000,
        deductible: 300,
    },
    PRO: {
        type: "PRO" as const,
        name: "Pro",
        price: 900,
        accidents: 100_000,
        losts: 500_000,
        dead: 500_000,
        deductible: 300,
    },
}
const PLAN_TYPES_ENUM = Object.values(PLAN_TYPES).map(({ type }) => type)
const PLAN_OBJECTS = Object.values(PLAN_TYPES)

const MIN_AGE = 3;
const MAX_AGE = 69;
const MIN_DATE_BIRTHDAY = dayjs().tz('Etc/GMT+6').subtract(MAX_AGE, 'years').format('YYYY-MM-DD')
const MAX_DATE_BIRTHDAY = dayjs().tz('Etc/GMT+6').subtract(MIN_AGE, 'years').format('YYYY-MM-DD')

const DEFAULT_VALUES: form = {
    name: "",
    surname1: "",
    surname2: "",
    phone: "",
    birthdate: dayjs("2000-12-31").toDate(),
    type: PLAN_TYPES.BASIC.type,
    cp: "",
    email: "",
    terms: true,
    recaptchaToken: null,
};

const birthdateValidation = (dateOfBirth: Date) => {
    try {
        const today = dayjs();
        const birthDate = dayjs(dateOfBirth);

        const age = today.diff(birthDate, "year");
        if (age > MIN_AGE && age <= MAX_AGE) {
        return true;
        } else {
        return false;
        }
    } catch (e) {
        return false;
    }
};

const REGEX_PHONE_NUMBER = /^\d{10}$/;

const SOCIAL_LINKS = {
    INSTAGRAM: 'https://www.instagram.com/tenku_seguros/',
    FACEBOOK: 'https://www.facebook.com/profile.php?id=100090335790653',
    ACCIDENT: 'tel:8004009911',
    CONTACT: 'mailto:contacto@tenkuseguros.com',
}

const WHATSAPP_CTA = {
    NUMBER: '+523337029836',
    MESSAGE: 'Hola, quiero más información sobre los seguros de Tenku...'
}

const VIDEO_SRC = 'https://www.youtube.com/embed/DPWau05czXI'

const CHANGE_PLAN_EVENT = 'CHANGE_PLAN' as const

export {
    PLAN_TYPES,
    PLAN_TYPES_ENUM,
    PLAN_OBJECTS,
    MIN_DATE_BIRTHDAY,
    MAX_DATE_BIRTHDAY,
    birthdateValidation,
    REGEX_PHONE_NUMBER,
    SOCIAL_LINKS,
    WHATSAPP_CTA,
    VIDEO_SRC,
    DEFAULT_VALUES,
    CHANGE_PLAN_EVENT
}

import ReactGA from "react-ga4";

export type Event = (props?: any) => void;
type Events = { [key: string]: Event }

const triggerEvent = (event: string, params?: any) => ReactGA.event(event, params);

const EVENTS: Events = {
    enterForm: () => triggerEvent('EnterForm'),
    enterLanding: () => triggerEvent('EnterLanding'),
    leaveLanding: (value) => triggerEvent('LeaveLanding', value),
    leaveForm: (value) => triggerEvent('LeaveForm', value),
    changePackage: (value) => triggerEvent('ChangePackage', value),
    leaveName: (value) => triggerEvent('LeaveName', value),
    leaveSurname1: (value) => triggerEvent('LeaveSurname1', value),
    leaveSurname2: (value) => triggerEvent('LeaveSurname2', value),
    leavePhone: (value) => triggerEvent('LeavePhone', value),
    changeBirthdate: (value) => triggerEvent('ChangeBirthday', value),
    leaveCp: (value) => triggerEvent('LeaveZipcode', value),
    leaveEmail: (value) => triggerEvent('LeaveEmail', value),
    sendForm: (value) => triggerEvent('SendForm', value),
    errorForm: (value) => triggerEvent('ErrorForm', value),
}

export default EVENTS

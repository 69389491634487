import * as React from "react";
import { Routes, Route } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

import theme from "./theme";

import { Home } from "./pages/Home";
import { Register } from "./pages/Register";
import { TermsAndConditions } from "./pages/TermsAndConditions";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="register" element={<Register />} />
      <Route path="terms" element={<TermsAndConditions />} />
    </Routes>
  </ChakraProvider>
);
